import axios from "axios";

function getHeaders()
{
    var headers = {
        "Content-Type": "application/json",
    };

    return headers;
}

function HandlerError(error) {
    if (error.response) {
        if (error.response.data instanceof String || typeof error.response.data == 'string') {
            if (error.response.status == 403 || error.response.data.indexOf('Unknown access_token') > -1 || error.response.data.indexOf('user is not authorized') > -1) {
                localStorage.setItem(A_TOKEN_LS, null);
                window.location.href = '/login?continue=true';
                return null;
            } else {
                console.log(`Server responded: ${error.response.data}`, error.response);
            }
        }
        else {
            console.log(`Server responded: ${error.response.status} - ` + error.response.data.message, error.response);
        }
    } else if (error.request) {
        console.log(`Request failed: `, error.request);
    } else {
        console.log(`Axios request execution fail: ${error.message}`, error);
    }

    return error.response?.data ?? "Unknown Error";
};

export default class Api
{
    constructor()
    {
        this.ServerUrl = 'https://mainapi.sywapps.com';
        this.ApiUrl = this.ServerUrl + '';
    }

    async GetWebsites()
    {
        try
        {
            return [
                { name: 'SYW Music', url: 'https://music.sywapps.com', description: 'A web app to share and display my musical creations and sketches.' }
            ];

            var res = await axios.get(this.ApiUrl + '/availableapps', { headers: getHeaders() });

            return res.data;
        }
        catch (e)
        {
            return HandlerError(e);
        }
    }

    async Set()
    {
        try
        {
            const dataStr = JSON.stringify(data, null, 4);

            var res = await axios.post(this.ApiUrl + '/set', dataStr, { headers: getHeaders() });

            return res;
        }
        catch (e)
        {
            return HandlerError(e);
        }
    }
}