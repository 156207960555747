import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faArrowUp, faBookDead, faCheck, faHandPaper, faImages, faPlus, faStop, faStopCircle, faTimes, faTrafficLight, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function OutroPage({ renderMobile, visible, timeLineOpen }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="outro"
            style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', color: 'white', textAlign: 'center', overflow: 'hidden' }}
        >
            <div style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', backgroundImage: 'url(/SYW_Logo.png)', backgroundSize: renderMobile ? 'contain' : 'cover' }}>
                <Grid style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', backgroundColor: 'rgba(20, 20, 20, 0.75)' }}>
                    <Grid style={{ height: '10%', width: '100%', paddingTop: '50px' }}>
                        <Typography variant="h4"><FontAwesomeIcon icon={faHandPaper} style={{ marginRight: '10px' }} />The End<FontAwesomeIcon icon={faTrafficLight} style={{ marginLeft: '10px' }} /></Typography>
                    </Grid>
                    <Grid style={{ height: '90%', width: '100%', paddingTop: '50px' }}>
                        <Grid style={{ width: '75%', height: '25%', margin: 'auto' }}>
                            <Typography variant="h6">Nothing more to see on this page!</Typography>
                        </Grid>
                        <Grid style={{ width: '75%', height: '50%', margin: 'auto' }}>
                            <Typography variant="subtitle2">If you'd like to view this website again:</Typography><br />
                            <Button variant="outlined"
                                style={{ width: '75%', height: '50%', backgroundColor: 'rgba(34, 221, 255, 0.5)' }}
                                onClick={() => { window.location.href = "#intro"; }}
                            >
                                Go Back to Top <FontAwesomeIcon icon={faArrowUp} style={{ marginLeft: '5px' }} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};