import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faImages, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";
import { UserInputComponent } from "../Global/UserInputComponent";


const useStyles = makeStyles(() => ({
    form: {
        width: '95%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function PrivacyPage({ setTitle, Api, renderMobile }) {
    useEffect(() => {
        setTitle && setTitle("Privacy");
    });

    const classes = useStyles();

    return (
        <div className={classes.form} style={{ height: '100%', overflowY: 'auto', textAlign: 'center', color: 'white', padding: '50px' }}>

        <Typography variant="h4">Privacy Policy</Typography>

        <Grid style={{ width: '100%', height: '95%', padding: '50px' }}>
            <Typography variant="h6">
                Yet to be written :)
            </Typography>
        </Grid>

        </div>
    );
};