import React, { useEffect, useRef, useState } from "react";
import "./app.css";

import { makeStyles } from "@material-ui/core/styles";

import { BrowserRouter as Router, Link, useParams } from "react-router-dom";

import { Redirect, Route, Switch } from "react-router-dom";

import { CircularProgress, Box, Grid, Button, IconButton, Typography, Toolbar, CssBaseline, AppBar, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import clsx from "clsx";

import MainContent from "./MainContent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFolder, faSignOutAlt, faHome, faArrowLeft, faInfo, faList, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useAPI, ApiProvider } from "./api/api-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NotificationProvider } from "./components/Global/NotificationContext";

import NotAuthorized from "./components/Global/NotAuthorized";
import InfoDialog from "./components/Global/InfoDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },
    appBar: {
        backgroundColor: '#222222',
        color: 'white',
        borderBottom: 'solid 1px #22DDFF',
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        backgroundColor: '#222222',
        color: 'white',
        borderBottom: 'solid 1px #22DDFF',
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginTop: '-64px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 2,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    logoutButton: {
        color: 'white',
        margin: '1px',
        height: '70%',
        width: '45%',
        fontSize: 'smaller',
        textTransform: 'none',
    },
    manageButton: {
        color: 'white',
        margin: '1px',
        height: '70%',
        width: '50%',
        fontSize: 'smaller',
        textTransform: 'none',
    }
}));

export default function App() {
    return (
        <>
            <ApiProvider>
                <NotificationProvider>
                    <Router>
                        <Switch>
                            <Route path={["/home", "/"]}>
                                <AppShell />
                            </Route>
                        </Switch>
                    </Router>
                </NotificationProvider>
            </ApiProvider>
        </>
    );
};

function AppShell() {
    const [title, setTitle] = useState("");
    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
        window.top.document.title = title
    }, [title]);

    const classes = useStyles();

    const { Api } = useAPI();

    const history = useHistory();

    const breakpoint = 800;
    const [renderMobile, setRenderMobile] = useState(window.innerWidth < breakpoint);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function onResize() {
            var mobileScreenSize = window.innerWidth < breakpoint;

            setRenderMobile(mobileScreenSize);
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', () => onResize());
        window.addEventListener('loaded', () => onResize());

        return () => {
            window.removeEventListener('resize', () => onResize());
            window.removeEventListener('loaded', () => onResize());
        };
    }, [breakpoint]);

    useEffect(() => {
        window.addEventListener('mousemove', (e) => {
            if (e.clientY > 120 || e.clientY <= 3)
            {
                setShowBar(false);
            } else {
                setShowBar(true);
            }
        });

        return () => {
            window.removeEventListener('mousemove', (e) => {
                if (e.clientY > 120 || e.clientY <= 3)
                {
                    setShowBar(false);
                } else {
                    setShowBar(true);
                }
            });
        };
    }, []);

    const [infoDialog, setInfoDialog] = useState({ show: false, title: <Typography variant="h2">Hello!</Typography>, content: <></>, icon: undefined, buttonText: undefined });
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const reference = params.get('ref');

        if (reference) {
            switch (reference.toLowerCase()) {
                case 'cv':
                    setInfoDialog({
                        show: true,
                        title: <Typography variant="h2">Welcome!</Typography>,
                        content:
                        <>
                            I see you are visiting this page from one of my resumes.<br />
                            <br />
                            SYW is short for my online nickname: ShutYourWaffle (yes, not very professional :/) and SYW Apps will be the name behind all my applications.<br />
                            <br />
                            This is only the main page for my domain and all my websites.<br />
                            I do not actively maintain any of these websites (but will try to), I simply use them for either showcasing, or easy access to tools and resources for myself and others.<br />
                            Here I will sometimes show a small portion of my recent activities and projects and provide some links and information about the other websites, services, and etc.<br />
                            <br />
                            Have a great day,<br />
                            Robbe Vrijenhoek (aka ShutYourWaffle)<br />
                        </>,
                        icon: undefined,
                        buttonText: undefined,
                    });
                    break;
            }
        }
    }, []);

    if (window.location.pathname == '/notauthorized') {
        return <NotAuthorized />
    }

    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: !(renderMobile || showBar),
                })}
            >
                <Toolbar style={{ width: '100%', display: 'flex' }}>
                    <Link to="/home">
                        <div style={{ width: '50px', height: '100%' }}>
                            <div style={{ width: '32px', height: '32px', backgroundImage: `url(/favicon.ico)`, backgroundSize: 'contain' }}></div>
                        </div>
                    </Link>
                    <Grid style={{ width: renderMobile ? '20' : '10%', overflow: 'hidden', wordWrap: '' }}>
                        <Button title="back to previous page"
                            style={{ color: 'white', textTransform: 'none' }}
                            onClick={() => history.goBack()}
                        >
                            <Typography variant="h6" noWrap className={classes.title}><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />Back</Typography>
                        </Button>
                    </Grid>
                    {renderMobile ? <></> :
                    <>
                    <Grid style={{ width: '7%', overflow: 'hidden', wordWrap: '' }}>
                        <Button title="about us"
                            style={{ color: 'white', textTransform: 'none' }}
                            onClick={() => history.push('/about')}
                        >
                            <Typography variant="subtitle1" noWrap className={classes.title}><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '5px', verticalAlign: 'middle' }} />About SYW Apps</Typography>
                        </Button>
                    </Grid>
                    <Grid style={{ width: '8%', overflow: 'hidden', wordWrap: '' }}>
                        <Button title="privacy policy"
                            style={{ color: 'white', textTransform: 'none' }}
                            onClick={() => history.push('/privacy')}
                        >
                            <Typography variant="subtitle1" noWrap className={classes.title}><FontAwesomeIcon icon={faList} style={{ marginRight: '5px', verticalAlign: 'middle' }} />Privacy Policy</Typography>
                        </Button>
                    </Grid>
                    </>}
                    <Grid style={{ paddingLeft: '5px', marginLeft: 'auto', marginRight: 0 }}>
                        <Grid style={{ marginLeft: 'auto', marginRight: 0 }}>
                            <Typography variant="subtitle2" noWrap className={classes.title} style={{ borderBottom: 'solid 1px white', marginLeft: 'auto', marginRight: 0 }}>
                                Welcome to SYW Apps!
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            
            {/*renderMobile ?
                <MobileAppDrawer
                    open={open}
                    onOpen={setOpen}
                    setTitle={setTitle}
                />
                :
                <AppDrawer
                    open={open}
                    onOpen={setOpen}
                    setTitle={setTitle}
                />
            */}

            <InfoDialog show={infoDialog.show}
                title={infoDialog.title}
                content={infoDialog.content}
                icon={infoDialog.icon}
                buttonText={infoDialog.buttonText}
                onClose={() => setInfoDialog(infoDialog => { return { ...infoDialog, show: false }; })}
            />

            <MainContent
                showBar={showBar}
                setShowBar={setShowBar}
                windowWidth={width}
                renderMobile={renderMobile}
                setTitle={setTitle}
                Api={Api}
                drawerOpen={open}
            />
        </>
    );
};