import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faExternalLinkAlt, faImages, faInfoCircle, faList, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function AllAppsPage({ renderMobile, visible, timeLineOpen, Api }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    const appStates = ['Not Maintained', 'Deployed', 'In Development / Usable', 'In Development / Unusable', 'Discontinued'];

    const apps = [
        { name: 'SpotifyVisualizer', note: 'Not maintained for long time. May be broken.', description: 'A Spotify visualizer app. A vizualizer that reads your currently playing spotify song and creates a reactive circle, line and/or bars based on the volume based data from spotify.', state: 0, url: 'spotifyviz', date: new Date(2020, 7, 1) },
        { name: 'RecipeFinder', note: 'Missing database input for actual usage.', description: 'A cooking and grocery shopping helper app. By telling the app what ingredient\'s you have in your kitchen, the app will provide you with recipes you can cook, including a tutorial. It can also help you doing grocery shopping by telling you what ingredients you miss to create a recipe and save a temporary grocery list for you.', state: 0, url: 'recipefinder', date: new Date(2022, 6, 24) },
        { name: 'Duurzaamheids Map', description: 'A Mapbox map app. Displays points whereat someone performed a ecologically positive action in The Netherlands. Credits to my sister for the idea.', state: 1, url: 'duurzaamheidmap', date: new Date(2022, 3, 29) },
        { name: 'SYW Music', description: 'A music playlist app. Listen to all ShutYourWaffle\'s musical creations and sketches on this website.', state: 1, url: 'music', date: new Date(2021, 7, 8) },
        { name: 'Vrijenhoek Photos', description: 'A photo displayer app. Store, view and share your pictures and videos with your friends and family.', state: 3, url: 'photos', date: new Date(2022, 1, 26) },
        { name: 'Twitch Chat AI', description: 'A Twitch Moderator Bot and app. A twitch bot that will read your chat and takes action based on your configuration, learning on the go. It can moderate for you using speech recognition. By saying keywords such as ban, timeout or other known commands you can keep your chat from misbehaving.', state: 4, url: 'twitchchatai', date: new Date(2022, 8, 22) },
        { name: 'TourneyBot', description: 'A Twitch and Discord bot that manages in game tournaments for all your community members and viewers. Generates fair teams, generates a fun bracket, tracks every players performance and creates a leaderboard per community.', state: 3, url: 'tourneybot', date: new Date(2022, 11, 5) },
        { name: 'MidiDomotica', description: 'A desktop app to control your system and setup using a midi keyboard, your voice, gestures and other events. You can control everything using custom self assignable events like a midi key press, speech sentence, or visible gesture. Control your lights, put your desktop into sleep mode, activate keyboard shortcuts with a single event, create your own soundboard with unlimited sounds and events, control your OBS if you\'re a streamer, and way more controllable features. Features may also be requested if they are not yet included.', state: 3, url: 'store', date: 'In Progress' },
        { name: 'HitSync', description: 'A desktop app which will make your rgb lights synchronize with your desktop\'s audio output. Colors, reactiveness and more can be configured and controlled within the app to make the lights react to your liking. Will automatically integrate with MidiDomotica when both apps are installed on the same device.', state: 3, url: 'store', date: 'In Progress' },
    ];

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="all-apps"
            style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, paddingTop: '50px', borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', color: 'white', textAlign: 'center', overflow: 'hidden' }}
        >
            <Grid style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%' }}>
                <Grid style={{ height: '10%', width: '100%', paddingTop: '50px' }}>
                    <Typography variant="h4"><FontAwesomeIcon icon={faList} style={{ marginRight: '10px' }} />All Sub Domains</Typography>
                </Grid>
                <Grid style={{ height: '90%', width: '100%', paddingTop: '50px', verticalAlign: 'top' }}>
                    <Grid style={{ height: '90%', width: renderMobile ? '90%' : '65%', margin: 'auto', overflowY: 'auto' }}>
                        <Table style={{ border: 'solid 1px #2DF', color: 'white' }}>
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#024', paddingBottom: '5px' }}>
                                    <TableCell align="left" style={{ width: '12%', color: 'white' }}>Name</TableCell>
                                    {renderMobile ? <></> : <TableCell align="left" style={{ width: '20%', color: 'white' }}>Description</TableCell>}
                                    <TableCell align="left" style={{ width: '10%', color: 'white' }}>State</TableCell>
                                    {renderMobile ? <></> : <TableCell align="center" style={{ width: '7%', color: 'white' }}>Info</TableCell>}
                                    <TableCell align="center" style={{ width: '20%', color: 'white' }}>Visit</TableCell>
                                    <TableCell align="center" style={{ width: '8%', color: 'white' }}>Last Updated</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apps.map((app, index) => (
                                <TableRow key={app.name + index} style={{ backgroundColor: index % 2 == 0 ? '#222' : '#224' }}>
                                    <TableCell align="left" style={{ width: '12%', color: 'white' }}>{app.name}{app.note ? <u><br />Note: {app.note}</u> : <></>}</TableCell>
                                    {renderMobile ? <></> : <TableCell align="left" style={{ width: '20%', color: 'white' }}>{app.description}</TableCell>}
                                    <TableCell align="left" style={{ width: '10%', color: 'white' }}>{appStates[app.state] || 'Unknown'}</TableCell>
                                    {renderMobile ?
                                    <></> :
                                    <TableCell align="center" style={{ width: '7%', color: 'white' }}>
                                        <Button component={Link} to={{ pathname: '/app/' + app.name }} style={{ color: 'white', width: '100%', height: '100%' }} disabled>
                                            More Info <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '5px' }}/>
                                        </Button>
                                    </TableCell>}
                                    <TableCell align="center" style={{ width: '20%', color: 'white' }}>
                                        {app.url == 'store' ? <i>Download from the SYW Store:</i> : <></>}
                                        <Button variant="outlined" component={Link} to={{ pathname: "https://" + app.url + ".sywapps.com" }} target="_blank" style={{ color: 'white', width: '100%', borderColor: 'white', textTransform: 'none' }}>
                                            {app.url == 'store' ? <>Download {renderMobile ? '' : 'from the Store'}</> : <>Visit {renderMobile ? '' : app.url + '.sywapps.com'}</>}<FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }}/>
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center" style={{ width: '8%', color: 'white' }}>{typeof app.date == 'string' ? app.date : app.date?.toDateString('dd MMMM yyyy') || 'Unknown'} </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};