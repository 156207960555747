import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography, Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faBackspace, faBackward, faBan, faCheck, faClock, faImages, faSmile, faTimes, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "./NotificationContext";
import { UserSelectInputComponent } from "./UserSelectInputComponent";
import { UserInputComponent } from "./UserInputComponent";


const useStyles = makeStyles(() => ({
    form: {
        width: '95%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: 'white',
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function InfoDialog({ onClose, show, buttonText, icon, title, content }) {

    const classes = useStyles();

    return (
        <Dialog open={show} maxWidth="xl" fullWidth onClose={(e, reason) => onClose(e, reason)} style={{ backgroundColor: '#222', padding: '15px', borderRadius: '1rem' }} >
            <DialogTitle style={{ backgroundColor: '#222', color: 'white', padding: '30px' }}>{title}</DialogTitle>
            <DialogContent style={{ backgroundColor: '#222', color: 'white', padding: '30px' }}>
                <Grid style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                    <Grid style={{ width: '100%', marginBottom: '10px' }}>
                        {content}
                    </Grid>
                    <Grid style={{ width: '100%', height: '20%' }}>
                        <Button variant="outlined"
                            style={{ color: '#2DF', borderColor: '#2DF', textTransform: 'none' }}
                            onClick={() => { onClose(); }}
                        >
                            {buttonText || 'OK'} <FontAwesomeIcon icon={icon || faCheck} style={{ marginLeft: '5px' }} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};