import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faGhost, faImages, faLightbulb, faNewspaper, faPlus, faSun, faTasks, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function UpcomingAppsPage({ renderMobile, visible, timeLineOpen, Api }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="new"
            style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', color: 'white', textAlign: 'center', overflow: 'hidden' }}
        >
            <Grid style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%' }}>
                <Grid style={{ height: '10%', width: '100%', paddingTop: '50px' }}>
                    <Typography variant="h4"><FontAwesomeIcon icon={faSun} style={{ marginRight: '10px' }} />Upcoming<FontAwesomeIcon icon={faTasks} style={{ marginLeft: '10px' }} /></Typography>
                </Grid>
                <Grid style={{ height: '90%', width: '80%', margin: 'auto', paddingTop: '50px' }}>
                    <Typography variant="h6">ShutYourWaffle is currrently not working on any publicly available application or is not sharing the progress on his newest project.</Typography>
                    <FontAwesomeIcon icon={faGhost} size="10x" style={{ marginTop: '50px' }} />
                </Grid>
            </Grid>
        </div>
    );
};