import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faImages, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";
import { UserInputComponent } from "../Global/UserInputComponent";
import IntroPage from "../MainPages/Intro";
import InfoPage from "../MainPages/Info";
import NewestAppsPage from "../MainPages/NewestApps";
import MusicPage from "../MainPages/Music";
import PopularAppsPage from "../MainPages/PopularApps";
import AllAppsPage from "../MainPages/AllApps";
import OutroPage from "../MainPages/Outro";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import { useRef } from "react";
import UpcomingAppsPage from "../MainPages/Upcoming";


const useStyles = makeStyles((theme) => ({
    timeline: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: 500,
        }),
        position: 'fixed',
        width: '15%',
        height: '100%',
        borderLeft: 'solid 1px #2DF',
        top: '0',
        right: '0',
        marginRight: '-15%',
    },
    timelineShow: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: 500,
        }),
        position: 'fixed',
        width: '15%',
        height: '100%',
        borderLeft: 'solid 1px #2DF',
        top: '0',
        right: '0',
        marginRight: '0',
    }
}));

export default function HomePage({ setTitle, Api, userName, renderMobile, showBar, setShowBar }) {
    useEffect(() => {
        setTitle && setTitle("Home");
    });

    const classes = useStyles();

    const { error, warning, success } = useNotifications();

    const [loaded, setLoaded] = useState(false);

    const [apps, setApps] = useState([]);

    useEffect(() => {
        setLoaded(false);

        Api.GetWebsites().then(res => {
            if (res instanceof String || typeof res == 'string') {
                warning('Failed to get SYW Web Apps!')
            }
            else if (res.status && res.status != 200) {
                if (res.detail) {
                    warning(`Sorry! ${res.detail}`);
                }
                else warning(`Failed to get SYW Web Apps!`);
                console.log(`Failed to get SYW Web Apps!`, res);
            }
            else {
                setApps(res);

                setLoaded(true);
            }
        });
    }, [Api, userName]);

    const pagesCount = 7;

    const [showTimeline, setShowTimeline] = useState(false);

    const [timelineDisabled, setTimelineDisabled] = useState(false);

    const disableTimeline = () => {
        setShowTimeline(false);
        setTimelineDisabled(true);

        setTimeout(() => {
            setTimelineDisabled(false);
        }, 5000);
    };

    const mainScrollRef = useRef(window.document.getElementById('main-scroll'));

    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
        if (!loaded) { return; }

        if (!mainScrollRef?.current) {
            mainScrollRef.current = window.document.getElementById('main-scroll');
        }

        if (mainScrollRef.current) {
            const handleScroll = () => {
                let value = mainScrollRef.current.scrollTop;

                let remaining = mainScrollRef.current.scrollHeight - (mainScrollRef.current.clientHeight - window.innerHeight);

                setScrollPercentage((value / remaining) * 100);
            };

            mainScrollRef.current.addEventListener('scroll', handleScroll);
            
            return () => mainScrollRef.current.removeEventListener('scroll', handleScroll);
        }
    }, [showTimeline, loaded]);

    useEffect(() => {
        window.addEventListener('mousemove', (e) => {
            if (e.clientX <= window.innerWidth * 0.85 || e.clientX >= window.innerWidth - 3)
            {
                setShowTimeline(false);
            } else {
                setShowTimeline(true);
            }
        });

        return () => {
            window.removeEventListener('mousemove', (e) => {
                if (e.clientX <= window.innerWidth * 0.85 || e.clientX >= window.innerWidth - 3)
                {
                    setShowTimeline(false);
                } else {
                    setShowTimeline(true);
                }
            });
        };
    }, []);

    if (!loaded) {
        return (
            <div className={classes.form} style={{ height: window.innerHeight * 0.8 }}>
                <Grid style={{ color: 'white', height: '100%', margin: 'auto', verticalAlign: 'middle' }}>
                    <Typography variant="h6">Loading...</Typography>
                    <CircularProgress size={75} style={{ marginTop: '10%', verticalAlign: 'middle', color: '#22DDFF' }} />
                </Grid>
            </div>
        );
    }

    const visiblePages = [];
    for (let i = 0; i < pagesCount; i++)
    {
        let pageMargin = (100 / pagesCount);
        let visible = scrollPercentage > (i == 0 ? -1 : (pageMargin * (i - 1)));

        if (visible) {
            visiblePages[i - 2] = 0;
            visiblePages[i - 1] = 1;
        }

        visiblePages[i] = visible ? 2 : -1;
    }

    const useUpcoming = true;

    return (
        <div style={{ width: '100%', height: window.innerHeight + 'px', overflow: 'hidden' }}>
            <div id="main-scroll" className={classes.form}
                style={{ width: (window.innerWidth + 17) + 'px', height: '100%', overflowY: 'auto', boxSizing: 'content-box' }}
            >

                <IntroPage renderMobile={renderMobile} visible={visiblePages[0] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} />

                <InfoPage renderMobile={renderMobile} visible={visiblePages[1] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} />

                <MusicPage renderMobile={renderMobile} visible={visiblePages[2] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} Api={Api} />

                {useUpcoming ?
                <UpcomingAppsPage renderMobile={renderMobile} visible={visiblePages[3] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} Api={Api} />
                :
                <NewestAppsPage renderMobile={renderMobile} visible={visiblePages[3] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} Api={Api} />
                }

                <PopularAppsPage renderMobile={renderMobile} visible={visiblePages[4] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} Api={Api} />

        	    <AllAppsPage renderMobile={renderMobile} visible={visiblePages[5] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} Api={Api} />

                <OutroPage renderMobile={renderMobile} visible={visiblePages[6] > 0} timeLineOpen={timelineDisabled ? false : showTimeline} />

            </div>

            {renderMobile ? <></> :
            <div id="main-timeline"
                className={clsx(classes.timeline, {
                    [classes.timelineShow]: timelineDisabled ? false : showTimeline
                })}
            >
                <Grid style={{ width: '100%', height: 'calc(100% - ' + (showBar ? 65 : 0) + 'px)', marginTop: (showBar ? 65 : 0) + 'px', marginLeft: 'auto', marginRight: '0', right: '0', backgroundColor: 'rgba(25, 25, 25, 0.9)', borderLeft: 'solid 1px rgba(80, 80, 80, 50%)' }}>
                    <Grid
                        style={{ display: 'inline-block', verticalAlign: 'top', width: '10%', height: '90%', margin: 'auto', marginTop: '10%', paddingRight: '1px', zIndex: '0', color: 'white' }}
                    >
                        <Grid
                            style={{ width: '100%', height: '5%' }}
                        >
                        </Grid>
                        <Grid
                            style={{ width: '100%', height: '95%' }}
                        >
                            <Grid
                                style={{ width: '100%', height: (100 / pagesCount) + '%', borderRight: 'solid 5px #2DF' }}
                            >
                            </Grid>
                            <Grid
                                style={{ width: '100%', height: scrollPercentage + '%', borderRight: 'solid 5px #2DF' }}
                            >
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ display: 'inline-block', verticalAlign: 'top', width: '80%', height: '90%', margin: 'auto', marginTop: '10%', marginRight: '10%', zIndex: '0', color: 'white' }}
                    >
                        <Button onClick={() => { disableTimeline(); }}
                            style={{ position: 'absolute', top: '0', right: '0', width: '50px', height: '50px', marginTop: (showBar ? 65 : 0) + 'px', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', textTransform: 'none', color: 'white', borderRadius: '0' }}
                        >
                                <FontAwesomeIcon icon={faTimes} />
                        </Button>

                        <Grid style={{ height: '5%', width: '100%', verticalAlign: 'bottom' }}>
                            <Typography variant="h5" style={{ height: '100%', width: '100%', verticalAlign: 'bottom' }}>TimeLine</Typography>
                        </Grid>
                        <Grid style={{ height: '95%', width: '100%' }}>
                            <Button onClick={() => { window.location.href = "#intro" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[0] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', borderTop: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                    <Typography variant="subtitle1">Intro</Typography>
                            </Button>

                            <Button onClick={() => { window.location.href = "#info" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[1] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                    <Typography variant="subtitle1">Info</Typography>
                            </Button>

                            <Button onClick={() => { window.location.href = "#music" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[2] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                    <Typography variant="subtitle1">Music</Typography>
                            </Button>

                            <Button onClick={() => { window.location.href = "#new" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[3] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                    <Typography variant="subtitle1">{useUpcoming ? 'Upcoming' : 'What\'s New'}</Typography>
                            </Button>

                            <Button onClick={() => { window.location.href = "#popular" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[4] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                    <Typography variant="subtitle1">Spotlight</Typography>
                            </Button>

                            <Button onClick={() => { window.location.href = "#all-apps" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[5] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                <Typography variant="subtitle1">All Apps</Typography>
                            </Button>
                            
                            <Button onClick={() => { window.location.href = "#outro" }}
                                style={{ width: '100%', height: (100 / pagesCount) + '%', backgroundColor: visiblePages[6] > 1 ? '#2DF' : '', paddingBottom: '10px', paddingLeft: '5px', verticalAlign: 'middle', borderLeft: 'solid 1px white', borderBottom: 'solid 1px #2AC', textTransform: 'none', color: 'white', borderRadius: '0' }}
                            >
                                <Typography variant="subtitle1">The End</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>}
        </div>
    );
};