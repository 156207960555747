import React, { useState } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import HomePage from "./components/Home";
import PrivacyPage from "./components/Home/Privacy";
import AboutPage from "./components/Home/About";

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxHeight: '100%',
        height: '100%',
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingTop: '65px',
        maxHeight: '100%',
        height: '100%',
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
}));

export default function MainContent({ Api, setTitle, renderMobile, windowWidth, showBar, setShowBar }) {
    const classes = useStyles();

    return (
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: showBar,
            })}
        >
            {/*<div className={classes.drawerHeader} />*/}
            <div
                style={{
                    width: windowWidth + 'px',
                    height: window.innerHeight + 'px',
                    overflow: 'hidden',
                }}
            >
                <Switch>
                    <Route exact path={["/home/index", "/home"]}>
                        <HomePage renderMobile={renderMobile}
                            showBar={showBar} setShowBar={setShowBar}
                            Api={Api} setTitle={setTitle}
                        />
                    </Route>
                    <Route exact path={["/about"]}>
                        <AboutPage renderMobile={renderMobile}
                            showBar={showBar} setShowBar={setShowBar}
                            Api={Api} setTitle={setTitle}
                        />
                    </Route>
                    <Route exact path={["/privacy"]}>
                        <PrivacyPage renderMobile={renderMobile}
                            showBar={showBar} setShowBar={setShowBar}
                            Api={Api} setTitle={setTitle}
                        />
                    </Route>
                    <Redirect strict from="/" to={`/home${window.location.search}`} />
                </Switch>
            </div>
        </main>
    );
};