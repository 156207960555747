import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faArrowLeft, faCheck, faExternalLinkAlt, faImages, faLink, faMusic, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function MusicPage({ renderMobile, visible, timeLineOpen, Api }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="music"
            style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', color: 'white', textAlign: 'center', overflowY: renderMobile ? 'auto' : 'hidden', overflowX: 'hidden' }}
        >
            <div style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', backgroundImage: 'url(/SYW_Logo.png)', backgroundSize: renderMobile ? 'contain' : 'cover' }}>
                <Grid style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', backgroundColor: 'rgba(20, 20, 20, 0.75)' }}>
                    <Grid style={{ height: '10%', width: '100%', paddingTop: '50px' }}>
                        <Typography variant="h4"><FontAwesomeIcon icon={faMusic} style={{ marginRight: '10px' }} />ShutYourWaffle's Music</Typography>
                    </Grid>
                    <Grid style={{ height: '90%', width: '100%' }}>
                        {renderMobile ?
                        <Grid style={{ display: 'inline-block', height: '20%', width: '100%', paddingTop: '50px' }}>
                            <Typography variant="h6">Listen to ShutYourWaffle's Musical Creations:</Typography>
                            <Button component={Link} to={{ pathname: "https://music.sywapps.com" }} target="_blank"
                                style={{ width: '80%', height: '100%', backgroundColor: '#2DF', color: 'black', textTransform: 'none' }}
                            >
                                Visit music.sywapps.com <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} />
                            </Button>
                        </Grid>
                        : <></>}
                        <Grid style={{ display: 'inline-block', height: renderMobile ? '70%' : '100%', width: renderMobile ? '100%' : '25%', padding: '20px', paddingTop: '50px', verticalAlign: 'top' }}>
                            {renderMobile ? <Typography variant="subtitle2" style={{ paddingLeft: '10px', paddingRight: '10px', borderTop: 'solid 1px white' }}>Or</Typography> : <></>}
                            <Typography variant="h6">ShutYourWaffle's Lifelong Playlist:</Typography>
                            <iframe style={{  borderRadius: '12px' }}
                                src="https://open.spotify.com/embed/playlist/4SpuBwdXL4tNiRfttBgtQv?utm_source=generator"
                                width="100%" height={renderMobile ? "100%" : "80%"} frameBorder="0" allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            >
                            </iframe>
                        </Grid>
                        {renderMobile ? <></> : <>
                        <Grid style={{ display: 'inline-block', height: renderMobile ? '30%' : '100%', width: renderMobile ? '100%' : '50%', padding: '20px', paddingTop: '50px', verticalAlign: 'top' }}>
                            <Grid style={{ display: 'inline-block', height: '70%', width: '100%' }}>
                                <Typography variant="h6">ShutYourWaffle's Musical Sketches and Creations:</Typography>
                                <iframe style={{  borderRadius: '12px' }}
                                    src="https://music.sywapps.com"
                                    width="100%" height="100%" frameBorder="0" allowFullScreen=""
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                >
                                </iframe>
                            </Grid>
                            <Grid style={{ display: 'inline-block', height: renderMobile ? '100%' : '20%', width: '100%', paddingTop: '10px' }}>
                                <Button  component={Link} to={{ pathname: "https://music.sywapps.com" }} target="_blank"
                                    style={{ width: '100%', height: '50%', backgroundColor: '#2DF', color: 'black', textTransform: 'none' }}
                                >
                                    Visit music.sywapps.com <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: 'inline-block', height: '100%', width: '25%', padding: '20px', paddingTop: '10%', verticalAlign: 'top', textAlign: 'center' }}>
                            <Typography variant="h6">
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />On the left:<br />
                                A huge Spotify playlist with every single spotify song ShutYourWaffle has liked and enjoyed listening to.<br />
                                Limited to the songs available on Spotify, but forever growing and already big enough you will probably not go through them all.<br />
                                <br />
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />In the middle:<br />
                                A website displaying all ShutYourWaffle's musical creations.<br />
                                Built by ShutYourWaffle himself.<br />
                            </Typography>
                        </Grid>
                        </>}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};