import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faImages, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    '@keyframes fade': {
        '0%': {
            opacity: '0.0'
        },
        '20%': {
            opacity: '1.0'
        },
        '80%': {
            opacity: '1.0'
        },
        '100%': {
            opacity: '0.0'
        }
    },
    '@keyframes fadeInverted': {
        '0%': {
            opacity: '1.0'
        },
        '20%': {
            opacity: '0.0'
        },
        '80%': {
            opacity: '0.0'
        },
        '100%': {
            opacity: '1.0'
        }
    },
    '@keyframes blinkGlow': {
        '0%': {
            backgroundColor: 'rgba(25, 25, 25, 1.0)',
        },
        '30%': {
            backgroundColor: 'rgba(0, 40, 175, 0.5)',
        },
        '40%': {
            backgroundColor: 'rgba(25, 25, 25, 0.0)',
        },
        '50%': {
            backgroundColor: 'rgba(0, 175, 175, 0.5)',
        },
        '60%': {
            backgroundColor: 'rgba(25, 25, 25, 0.0)',
        },
        '70%': {
            backgroundColor: 'rgba(0, 175, 30, 0.5)',
        },
        '100%': {
            backgroundColor: 'rgba(25, 25, 25, 1.0)',
        }
    },
    background: {
        width: '100%',
        height: '100%',
        animationName: '$blinkGlow',
        animationDuration: '30s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        top: 0
    },
    backgroundImage: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        animationName: '$fade',
        animationDuration: '30s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        backgroundImage: 'url(/SYW_Logo.png)',
        backgroundSize: 'cover',
        top: 0
    },
    hiddenText: {
        width: '100%',
        height: '50%',
        animationName: '$fadeInverted',
        animationDuration: '30s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        top: 0,
    }
}));

export default function IntroPage({ renderMobile, visible, timeLineOpen }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="intro"
            style={{ height: window.innerHeight + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', overflow: 'hidden' }}
        >
            <Grid className={classes.backgroundImage} style={{ backgroundSize: renderMobile ? 'contain' : 'cover' }}>
                <Grid className={classes.background} style={{ height: window.innerHeight + 'px', width: '100%' }}>
                    <Grid className={classes.hiddenText}>
                        <Grid style={{ width: '100%', height: '50%', color: 'white' }}>
                        </Grid>
                        <Grid style={{ width: '100%', height: '25%', color: 'white' }}>
                            <Typography variant="h1" style={{ width: '100%', height: '100%', fontSize: '10vh', textAlign: 'center', verticalAlign: 'bottom' }}>SYW Apps</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', height: '25%', color: 'white' }}>
                            <Typography variant="h2" style={{ width: '100%', height: '10%', fontSize: '5vh', textAlign: 'center', verticalAlign: 'middle' }}>by ShutYourWaffle</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};