import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faImages, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function InfoPage({ renderMobile, visible, timeLineOpen }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="info"
            style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, paddingTop: '50px', borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', overflow: 'hidden' }}
        >
            <Grid style={{ width: '100%', height: renderMobile ? '10%' : '5%', borderBottom: 'solid 1px black', verticalAlign: 'top' }}>
                <Grid style={{ display: 'inline-block', width: '20%', textAlign: 'center' }}>
                </Grid>
                <Grid style={{ display: 'inline-block', width: '20%', paddingLeft: '5px', paddingRight: '5px', textAlign: 'center', verticalAlign: 'top' }}>
                    <Button variant="outlined"
                        style={{ color: '#2DF', borderColor: '#2DF', backgroundColor: '#222', width: '100%' }}
                        component={Link} to="/about"
                    >
                        About{renderMobile ? '' : ' SYW Apps'}
                    </Button>
                </Grid>
                <Grid style={{ display: 'inline-block', width: '20%', textAlign: 'center' }}>
                </Grid>
                <Grid style={{ display: 'inline-block', width: '20%', paddingLeft: '5px', paddingRight: '5px', textAlign: 'center', verticalAlign: 'middle' }}>
                    <Button variant="outlined"
                        style={{ color: '#2DF', borderColor: '#2DF', backgroundColor: '#222', width: '100%' }}
                        component={Link} to="/privacy"
                    >
                        Privacy
                    </Button>
                </Grid>
                <Grid style={{ display: 'inline-block', width: '20%', textAlign: 'center' }}>
                </Grid>
            </Grid>

            <Grid style={{ width: '100%', height: renderMobile ? '90%' : '95%', textAlign: 'center', color: 'white', padding: '50px', overflowY: 'auto' }}>
                <Typography variant="h3">
                    NOTE: This main page is still in development!<br /><br />
                </Typography>

                <Typography variant="h6">
                    sywapps.com or SYW Apps as a Web Domain, is a publicly available web domain containing many sub domains.<br />
                    Each sub domain hosts a website or app built by the creator behind SYW Apps, better known as ShutYourWaffle.<br />
                    This main page for the domain is here for global information about the domain and for a quick tour through what's publicly available.<br />
                </Typography>
            </Grid>
        </div>
    );
};