import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faCheck, faChevronCircleLeft, faChevronCircleRight, faFire, faImages, faLink, faMusic, faPlus, faTimes, faTrash, faUpload, } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotifications } from "../Global/NotificationContext";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '0',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formShift: {
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: 500,
        }),
        opacity: '100%',
        width: '100%',
        height: '100%',
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    txt: { textAlign: "center" },
    continue: {
        marginTop: "20px",
        width: "20%",
    },
}));

export default function PopularAppsPage({ renderMobile, visible, timeLineOpen, Api }) {

    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    const [previewIndex, setPreviewIndex] = useState(0);

    const previews = [
        { path: '/HitSync_Preview1.mp4', name: 'Further Away', link: 'https://music.sywapps.com/FURTHER%20AWAY' },
        { path: '/HitSync_Preview2.mp4', name: 'TSK, Borealis - Raijin', link: 'https://open.spotify.com/track/3319HO38GlkmquvpPx7wRl?si=261e91adefb346e2' },
        { path: '/HitSync_Preview3.mp4', name: 'My Bop', link: 'https://music.sywapps.com/FM%20-%20My%20BOP%20remake' },
        { path: '/HitSync_Preview4.mp4', name: 'Danny Olson, yetep - Melting (feat. EASAE)', link: 'https://open.spotify.com/track/0gsPInjPU6qsGGdLnpgTHD?si=261e91adefb346e2' },
    ];

    const [autoNextPreview, setAutoNextPreview] = useState(false);

    const autoPlayNextPreview = () => {
        setPreviewIndex(index => index == previews.length - 1 ? 0 : index + 1);

        setAutoNextPreview(true);
    };

    useEffect(() => {
        if (!autoNextPreview) { return }

        setAutoNextPreview(false);
    }, [visible]);

    return (
        <div className={clsx(classes.form, {
                [classes.formShift]: visible,
            })}
            id="popular"
            style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', margin: 'auto', marginLeft: 0, left: 0, borderBottom: 'solid 1px #2DF', boxSizing: 'border-box', color: 'white', textAlign: 'center', overflow: 'hidden' }}
        >
            <div style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width: '100%', backgroundImage: 'url(/SYW_Logo.png)', backgroundSize: renderMobile ? 'contain' : 'cover' }}>
                <Grid style={{ height: (window.innerHeight - (renderMobile ? 60 : 0)) + 'px', width:  '100%', backgroundColor: 'rgba(20, 20, 20, 0.75)' }}>
                    <Grid style={{ height: '10%', width: '100%', paddingTop: '50px' }}>
                        <Typography variant="h4"><FontAwesomeIcon icon={faFire} style={{ marginRight: '10px' }} />In the Spotlight<FontAwesomeIcon icon={faFire} style={{ marginLeft: '10px' }} /></Typography>
                    </Grid>
                    <Grid style={{ height: '90%', width: '100%', paddingTop: '50px' }}>
                        <Grid style={{ width: '100%', height: '10%' }}>
                            <Typography variant="h5">HitSync</Typography>
                        </Grid>
                        <Grid style={{ width: '90%', height: '15%', marginLeft: 'auto', marginRight: 'auto', overflowY: 'auto' }}>
                            <Typography variant="h6">
                                HitSync is a live Hue Lights music synchronizer.<br />
                                The available modes will change your Hue lights' colors to match the music you are streaming.<br />
                                You can configure what colors to show and how reactive the synchronization should be inside the app.<br />
                                Not yet for download<br />
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '100%', height: '75%' }}>
                            <Grid style={{ display: 'inline-block', width: renderMobile ? '20%' : '25%', height: '100%', verticalAlign: 'top' }}>
                                {renderMobile ?
                                <>
                                <Grid style={{ width: '100%', height: '25%' }}></Grid>
                                <Grid style={{ width: '100%', height: '50%', verticalAlign: 'middle', paddingRight: '20px' }}>
                                    <Button variant="outlined"
                                        style={{ backgroundColor: '#2DF', textTransform: 'none', width: '100%', height: '100%', verticalAlign: 'middle' }}
                                        disabled={previewIndex == 0}
                                        onClick={() => setPreviewIndex(index => index - 1)}
                                    >
                                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                                    </Button>
                                </Grid>
                                </>
                                :
                                <>
                                <Grid style={{ display: 'inline-block', width: renderMobile ? '10%' : '80%', height: '100%', verticalAlign: 'top' }}></Grid>
                                <Grid style={{ display: 'inline-block', width: renderMobile ? '90%' : '20%', height: '100%', verticalAlign: 'top' }}>
                                    <Grid style={{ width: '100%', height: '40%', verticalAlign: 'top' }}></Grid>
                                    <Grid style={{ width: '100%', height: '20%', verticalAlign: 'top', paddingRight: '20px' }}>
                                        <Button variant="outlined"
                                            style={{ backgroundColor: '#2DF', textTransform: 'none', width: '100%', height: '100%', verticalAlign: 'middle' }}
                                            disabled={previewIndex == 0}
                                            onClick={() => setPreviewIndex(index => index - 1)}
                                        >
                                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                </>}
                            </Grid>
                            <Grid style={{ display: 'inline-block', width: '50%', height: '100%', verticalAlign: 'top' }}>
                                {renderMobile ? <></> : <Typography variant="subtitle1">Watch the Previews- Current Song: {previews[previewIndex].name}</Typography>}
                                <video controls
                                    autoPlay={autoNextPreview}
                                    width="100%"
                                    height={renderMobile ? "70%" : "80%"}
                                    src={previews[previewIndex].path}
                                    onEnded={() => autoPlayNextPreview()}
                                    onPause={() => { if (autoNextPreview) setAutoNextPreview(false); }}
                                />
                                <Typography variant="subtitle1">(Recorded with Phone, Turn volume up) - Original audio: {previews[previewIndex].name}</Typography>
                                <Button variant="outlined" component={Link} to={{ pathname: previews[previewIndex].link }} target="_blank"
                                    style={{ backgroundColor: '#2DF', textTransform: 'none' }}
                                >
                                    Link to Song <FontAwesomeIcon icon={faMusic} style={{ marginLeft: '5px' }} />
                                </Button>
                                <Button variant="outlined" component={Link} to={{ pathname: renderMobile ? 'https://imgur.io/a/WvByNh1' : 'https://imgur.com/a/WvByNh1' }} target="_blank"
                                    style={{ backgroundColor: '#2DF', textTransform: 'none', marginLeft: '20px' }}
                                >
                                    More on imgur (bottom) <FontAwesomeIcon icon={faLink} style={{ marginLeft: '5px' }} />
                                </Button>
                            </Grid>
                            <Grid style={{ display: 'inline-block', width: renderMobile ? '20%' : '25%', height: '100%', verticalAlign: 'top' }}>
                                {renderMobile ?
                                <>
                                <Grid style={{ width: '100%', height: '25%' }}></Grid>
                                <Grid style={{ width: '100%', height: '50%', verticalAlign: 'middle', paddingLeft: '20px' }}>
                                    <Button variant="outlined"
                                        style={{ backgroundColor: '#2DF', textTransform: 'none', width: '100%', height: '100%', verticalAlign: 'middle' }}
                                        disabled={previewIndex == previews.length - 1}
                                        onClick={() => setPreviewIndex(index => index + 1)}
                                    >
                                        <FontAwesomeIcon icon={faChevronCircleRight} />
                                    </Button>
                                </Grid>
                                </>
                                :
                                <>
                                <Grid style={{ display: 'inline-block', width: renderMobile ? '90%' : '20%', height: '100%', verticalAlign: 'top' }}>
                                    <Grid style={{ width: '100%', height: '40%', verticalAlign: 'top' }}></Grid>
                                    <Grid style={{ width: '100%', height: '20%', verticalAlign: 'top', paddingLeft: '20px' }}>
                                        <Button variant="outlined"
                                            style={{ backgroundColor: '#2DF', textTransform: 'none', width: '100%', height: '100%', verticalAlign: 'middle' }}
                                            disabled={previewIndex == previews.length - 1}
                                            onClick={() => setPreviewIndex(index => index + 1)}
                                        >
                                            <FontAwesomeIcon icon={faChevronCircleRight} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid style={{ display: 'inline-block', width: '80%', height: '100%', verticalAlign: 'top' }}></Grid>
                                </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};